.navbar {
  background: linear-gradient(#000 5%, #222 10%, #111 55%, #000 70%);
  height: 80px;
  display: flex;
  align-items: center;
  font-size: calc(var(--smallFont) + .15rem);
  width: 100vw;
  position: fixed;
  top: 0px;
  justify-content: space-between;

}


.navbar-logo {
  justify-self: center;
  margin-left: 40px;
  cursor: pointer;
  text-decoration: none;
  font-size: calc(var(--largeFont) + .5rem);
  color: #ecbc62;
  height: 100%;
  font-family: 'PenumbraSerifStd-Semibold';
  position: relative;
  display: inline-block;
  letter-spacing: 3px;
  transition: transform 0.3s ease;

}
/*
.navbar-logo::after {
  content: '';
  width: 100%;
  height: 300px;
  background-image: url('/src/mirandushub/pics/sword.png');
  position: absolute;
  z-index: -1;
  background-position: center;
  background-size: contain;
  transform: rotate(240deg);
  left: -500px;
  top: -150px;

}
*/

.navbar-logo:hover {
  text-decoration: none;
  color: #ecbc62;
  text-shadow: 0 1px 4px #790cbd, 0 1px 4px #790cbd, 0 1px 4px #790cbd;
  letter-spacing: 3.5px;
  transform: translateY(-1.5px);

}
.navbar-logo:hover::after {
  left: 0;
  animation: sword-in-monitor 1.5s;
}

@keyframes sword-in-monitor {
  0% {
    left: -500px;
    transform: rotate(227deg);
    opacity: 0;
    filter: blur(5px);
  }
  20% {
    left: 50px;
    transform: rotate(227deg);
    opacity: 0.5;
    filter: blur(0px);
  }

  100% {
    left: 0px;
    transform: rotate(240deg);
    opacity: 1;
    filter: blur(0px);
  }
}

.logoContainer {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  margin-right: 40px;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  width: 200px;
  justify-content: center;

}

#nav-links {
  color: #cde;
  text-decoration: none;
  padding: 0.5rem 15px;
  display: flex;
  transition: 0.5s ease;
  font-family: 'Cinzel';
  text-shadow: 0 0 10px #000, 0 0 13px black, 0 0 8px black, 0 0 1px black;
  font-weight: 600;
  font-size: clamp(.9rem, 1.5vw, 1.2rem);
  max-width: 100%; /* Add this line */
  justify-content: center;
  border-radius: 0px;
  transition: transform 0.3s ease;
}


#nav-links:hover {
  position: relative;
  transition: all 0.2s ease-out;
  color: #ecbc62;
  text-shadow: 0 2px 8px #790cbd;
  letter-spacing: 1px;
  transform: translateY(-5px);

}

#nav-links:hover::before {
  background: linear-gradient(#535d6b, #000 70%);
  background-color: #1c2026;
  border-radius: 4px;
  border-radius: 15px 15px 0 0;
  position: absolute;
  width: 110%;
  bottom: -5px;
  content: '';
  height: 100%;
  animation: nav-item-bg-up .15s ease;
  z-index: -1;

}

.rotated-letter {
  transition: all 0.2s ease-out;
  font-family: 'Cinzel';
}
.logoLetter {
  font-family: 'PenumbraSerifStd-Regular';
}
@keyframes nav-item-bg-up {
  0% {
    opacity: 0;
    bottom: -100%;
  }
  10% {
    bottom: -90%;
  }
  30% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  99% {
    bottom: -1px;
  }
  100% {
    bottom: -5px;
  }
  
}
.fa-caret-down {
  margin: 8px 0 0 5px;
}
.fa-bars {
  color: #fff;
}

#nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}


  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInScaleUp {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .welcome-to,
  .mirandus-hub {
    display: none; /* Initially, hide the text on all devices */
    text-align: center;
    opacity: 0;
  }
  
  .welcome-to {
    transition: opacity 2s ease-in; /* 2s fade-in effect */

  }
  .mirandus-hub {
    transition: opacity 2s ease-in; /* 4s fade-in effect */

  }

  .tabletH1 {
    display: none;

  }

  @keyframes sword {
    0% {
      transform: translateX(1500px) rotate(50deg);
    }
    40% {
      transform: translateX(-100px) rotate(45deg);
    }
    100% {
      transform: translateX(0) rotate(12deg);
    }
  }
  

@media screen and (max-width: 1200px) {

  .navbar-logo::after {
    display: none;
  }
  .tabletH1 {
    display: block;
    margin: auto;
    width: 500px;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 1rem;
    animation: slideInFromLeft 0.5s forwards;

  }

  .nav-menu.active {
    background: linear-gradient(to left, #000, #222, #222, #000);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 2000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
    animation: slideInFromLeft 0.5s forwards;
  }
  

  #nav-links:hover::before {
    display: none;
  }
  #nav-links {
    text-align: center;
    padding: 2rem 0px;
    width: 100vw;
    color: #ecbc62;
    position: relative;
    font-size: var(--largeFont);

  }
  #menu-items {
    margin: 0px;
    margin-bottom: 5%;
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 5%;
  }

  .menu-item {
    border: 2px solid #ecbc62;
    background: linear-gradient(to bottom, #111 50%, #210631);
    border-radius: 5px;
    color: #ecbc62;
    text-shadow: none;
    text-shadow: 0 2px 8px #790cbd;
    font-size: calc(var(--largeFont));
    width: 230px;
    padding: 15px 30px;
    margin: 8% auto;
    box-shadow: 0 0 3px inset #ecbc62 !important;

  }

  /*on hover, turns to a nav-item*/
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
    max-width: 100%; /* Add this line */
    justify-content: center;
  }
  

  #nav-links:hover {
    background-color: #000;
    border-radius: 15px;
  }

  #nav-links::after {
    opacity: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(5%, 25px);
  }
  .navbar-logo:hover {
    transform: translate(5%, 25px);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  #nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #ccddee50;
    text-decoration: none;
    color: #fff;
    font-size: clamp(1.5rem, 2vw, 2rem);
    
  }

  #nav-links-mobile:hover {
    background: #fff;
    color: #ccddee50;
    border-radius: 15px;
    transition: 250ms;

  }

  .fa-caret-down {
    display: none;
  }

  #nav-links:hover {
    background: #cde;
    color: black;
    border-radius: 0px;

  }



  #menu {
    position: initial;
  }

}



@media (max-width: 450px) {
  .logoLetter {
    font-size: 1rem;
  }
  #menu {
    margin-top: 0px;
    margin-bottom: 30%;
  }
  #menu-background-image,
  #menu-background-imageAssets {
    position: absolute;
  }
  .tabletH1 {
    display: none;
  }
  .welcome-to {
    display: block; /* Display the text only on mobile devices */
    color: #ab4ae7;
    font-family: 'PenumbraSerifStd-Regular';
    font-size: var(--smallFont);
    padding-top: 100px;
    text-shadow: 0 1px 4px #790cbd;
    padding-left: 20%;
    text-align: left;

  }
  .mirandus-hub {
    display: block; /* Display the text only on mobile devices */
    color: #ecbc62;
    font-family: 'PenumbraSerifStd-Bold';
    font-size: calc(var(--veryLargeFont) + .75rem);
    text-shadow: 0 1px 4px #790cbd, 0 1px 4px #790cbd, 0 1px 4px #790cbd, 0 5px 14px #210631, 0 10px 40px #360257;
    letter-spacing: 3px;
  }

  .blackBG {
    content: '';
    display: block;
    width: 100%;
    height: 35vh;
    background: linear-gradient( to bottom, #111111cc 60%, #11111199 85%, #11111150 90%, transparent);
    z-index: -1;
  }
  
  .homeMobileHeader {
    display: block;
    height: 60vh;
    z-index: 1; /* Add this line */
    position: relative;
  }
  

  .sword {
    height: auto;
    width: 80%;
    position: absolute;
    left: 50px;
    top: 50px;
    z-index: -1;
    transform: rotate(12deg);
    animation: sword 2s;
  }
}


/* Add a class to apply the fade-in effect */
.fade-in {
  opacity: 1;
}
.fade-in-long {
  opacity: 1;
}

.visitor-count {
  display: none;
  color: #555;
  font-family: 'PenumbraSerifStd-Bold';
  width: fit-content;
  font-size: var(--smallFont);
  z-index: 10000;
  margin: auto;
  transform: translateY(-7px);
  margin-left: 15px;
  animation: disappear 2s 6s;
}

@keyframes disappear {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
  
}